@import '../../scss/client_master.scss';

$InputPanel-border: 1px solid #ccc !default;
$InputPanel-border-top: null !default;
$InputPanel-border-bottom: null !default;
$InputPanel-border-left: null !default;
$InputPanel-border-right: null !default;
$InputPanel-padding: 1em !default;
$InputPanel-box-shadow: null !default;
$InputPanel-background-color: #fff !default;
$InputPanel-heading-margin-bottom: 0 !default; 
$InputPanel-heading-title-font-size: 16px !default;
$InputPanel-heading-title-font-weight: 400 !default;
$InputPanel-heading-title-font-family: "Open Sans", Helvetica, sans-serif !default;

.InputPanel {
  background-color: $InputPanel-background-color;
  border: $InputPanel-border;
  border-top: $InputPanel-border-top;
  border-bottom: $InputPanel-border-bottom;
  border-left: $InputPanel-border-left;
  border-right: $InputPanel-border-right;

  padding: $InputPanel-padding;

  box-shadow: $InputPanel-box-shadow;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: $InputPanel-heading-margin-bottom;

    .title {
      flex-grow: 1;
      font-size: $InputPanel-heading-title-font-size;
      font-weight: $InputPanel-heading-title-font-weight;
      font-family: $InputPanel-heading-title-font-family;
    }

    .btn-link {
      font-size: 22px;
      padding-bottom: 10px;
      color: #212529;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
