@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: #FFFFFF;

	border-top: 12px solid #0079C1;

	.disclaimer_container {
		// padding:15px 0;

		div {
			   display:flex;
			   justify-content: left;
			   flex-wrap: wrap;

			   img {
					  max-width: 100%;
					  margin-bottom:15px;
			   }

			   p {
					  color:#113653;
					  text-align: left;
					  margin:0;
					  font-size:12px;
			   }
		}


 }

	.itemList {
		h6 {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 700;
			font-size: 13px;
			line-height: 24px;
			text-transform: uppercase;

			letter-spacing: 0.15px;
			

			color: #000000;

		}
		p {			
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;

			letter-spacing: 0.15px;

			color: #000000;
		}
	}

	.contactList {
		margin: 0 0 0 auto;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.row {
			width: 100%;
			margin: 0;

			padding: 0 0 23px 0;

			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			border-bottom: 3px solid #666666;
		}

		p {			
			margin: 15px 0 0;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;

			letter-spacing: 0.15px;

			color: #000000;
		}

		.blue {
			color: #0079C1;

			svg {
				transform: rotate(90deg);
				margin-right: 8px;
				font-size: 10px;
			}
		}
		
	}

	.smallLogo {
		margin: 24px 0;

		img {			
			height: 77px;
			width: 235px;
		}
	}

	.copyright {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;

		letter-spacing: 0.15px;

		color: #000000;
	}

	.institutionLinks {
		margin: 16px 0;

		display: flex;
		justify-content: flex-start;
		align-items: center;

		a {
			margin: 0 0 0 6px;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			
			letter-spacing: 0.15px;
			
			color: #000000;

			p {
				margin: 0;
			}
		}
			
		a:first-child {
			margin: 0px;
		}
			
		&:first-child {
			margin: 0px;
		}
	}

	.disclaimer {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		
		letter-spacing: 0.15px;
		
		color: #000000;
	}
}