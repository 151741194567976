@import "src/client_customizations/scss/client_master.scss";

.TabbedMaps {
  background-color: #E5E5E5;
  padding: 39px 0;
  .tabbed-maps-panel {
    padding: 0;
    border-radius: 3px;
    background: white;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
  }
  .nav-tabs {
    border-radius: 3px 3px 0 0;

    background-color: #ffffff;
    padding: 0 1.5rem;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      cursor: pointer;
      border-radius: 0;
      padding: 1.5rem 0;
      font-weight: 400;
      margin: 0 1rem;
      border: none;
      font-size: 18px;
    }

    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      color: $blue;
        box-shadow: inset 0 -5px 0 0 $blue;
    }

  }
  .titleContainer {    
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title {
    margin: 0 auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #333333;

  }

  .subtitle {
    max-width: 730px;

    margin: 6px auto 50px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    text-align: center;

    color: #333333;
  }

  .ZipcodeInputWithButton {
    button, input, select, .input-group {
      height: 48px;
    }
  }

  .tableHeader {
    margin: 48px 0 8px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    p {
      margin: 0 auto 0 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;


      color: #000000;
    }
  }

  .tableRow {
    margin: 0 0 0;
    height: 40px;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    background-color: white;

    &.gray {
      background-color: #EEEEEE;
    }

    &:last-child {
      border-bottom: 1px solid #B0B0B0;
    }

    p {
      width: 100%;
      height: 40px;

      
      display: flex;
      align-items: center;

      padding: 0 12px;
      margin: auto auto auto 0;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      
      
      color: #000000;
      border-top: 1px solid #B0B0B0;
      border-right: 1px solid #B0B0B0;

      &:first-child {
       border-left: 1px solid #B0B0B0;        
      }
    }

    a {
      width: 100%;
      height: 40px;
      
      display: flex;
      align-items: center;

      padding: 0 12px;
      margin: auto auto auto 0;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      
      
      color: #0079C1;
      border-top: 1px solid #B0B0B0;
      border-right: 1px solid #B0B0B0;
    }
  }
}
