@import "src/client_customizations/scss/client_master.scss";

.IncentivesPage {
  div {
    text-align: center;
  }

  .subtitle {
    margin: 8px auto 30px;
    text-align: center;
    max-width: 540px;
  }

  .button {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    width: 220px;
    
    background: #0079C1;
    border-radius: 2px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: #FFFFFF;
  }
}
