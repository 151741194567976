.RateOptionsWizard {
  .modal-body {
    min-height: 300px;

    p.h1 {
      font-weight: bold;
    }

    .form-group-half {
      display:flex;

      .form-group {
        width:50%;

        &:first-child {
          margin-right:15px;
        }

        &:last-child {
          margin-left:15px;
        }
      }
    }

    .form-group {
      .label-range {
        text-align: center;
        margin: 0 auto !important;
        display: block !important;
      }
    }
  }

  .modal-footer {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .btn {
      min-width: 200px;
      padding:15px;
      @media (max-width: 767px) {
        display: block;
        margin-bottom: 0.5em;
      }

      &.btn-secondary {
        border:2px solid #0079C1;
        background-color:white;
        color:#0079C1;
      }
    }
  }

  label {
    text-transform: uppercase;
  }

  .ButtonGroup {
    .btn {
      background-color: #E5E5E5;
      border-radius: 0;
      height: 80px;

      &.active {
        background-color: #0079C1;
        color: #ffffff;
      }
    }
  }
}
