@import "src/client_customizations/scss/client_master.scss";

#HomepageVehiclesCarousel {
  background-color: #f3f4f4;
  padding: 64px 0;

  .textContainer {
    margin: 0 0 48px;

    max-width: 1060px;;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 49px;

      text-align: center;
      letter-spacing: 0.15px;

      color: #000000;
    }

    p {      
      max-width: 730px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.15px;

      color: #000000;
    }

    .buttonRow {
      width: auto;
      height: auto;
  
      display: flex;
      justify-content: center;
      align-items: center;

      button, .button {
        margin: 0 15px;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;

        padding: 15px 33px;

        background: #FFFFFF;
        border: 4px solid #0079C1;
        border-radius: 2px;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;

        color: #0079C1;
      }

    }
  }
}
