@import "src/client_customizations/scss/client_master.scss";
$color_1: #666666;
$color_2: #333333;
$color_3: #252525;

.RenderItemTitle {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	
	color: #2B2B2B;
}
.RenderItemValue {
	font-weight: 400;
	color: $RenderItem-item-value-color;
	.denominator {
		font-weight: 700;
		font-size: 0.8rem;
		color: $RenderItem-item-value-denominator-color;
	}
}
.h2.RenderItemValue {
	font-size: 1.75rem;
}
p.RenderItemValue {
	font-size: 1.2rem;
}
