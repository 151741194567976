@import "src/client_customizations/scss/client_master.scss"; 

.ChargerCard p {
  width: 100%;
  margin-bottom: 0;
}

.ChargerCardTop, .ChargerCardBottom > * {
  padding: 8px;
}

.ChargerCardTop {
  position: relative;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .charger-compatibility-warning {
    font-size: 10px;
    margin: 1em auto;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}

.ChargerCardBottom {
//   border-top: $EVCard-EVCardBottom-border;
  display: flex;
  flex-direction: column;

//   > * {
//     flex-grow: 1;
//     padding-top: 10px;
//     padding-bottom: 10px;
//   }

//   div:first-child {
//     border-right: $EVCard-EVCardBottom-border;
//   }
}

.buy-charger {
    text-align: center;
}

.ChargerCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

.charger-row {
  display:flex;
    p {
      padding-left: 8px;
    }
}

.charger-price {
  font-weight: 600;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  .ChargerCardTop {
    padding: 15px 15px 10px 15px;
  }

  .ChargerCardBottom > * {
    padding: 0;
  }

  .ChargerCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}