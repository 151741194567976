.ChargingStationsMapLegend {
  width: 100%;
  margin: auto 0 0;

  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;

  .charger-type {
    height: 48px;
    margin: 0 0 20px;
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    b {    
      margin: 0 0 0 8px;
        
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #000000;
    }
  }

  .charger-type:last-child {
    margin-bottom: 0;
  }

  .charger-type img {
  }
}
