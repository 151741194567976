@import "src/client_customizations/scss/client_master.scss";

#ev-jumbotron-details {
       .show-full-list-container {
              display: block;
              text-align: left;

              button {
                     margin:0 0 25px;
                     padding-left:50px;
                     padding-right:50px;
              }

              .CalcPanels {
                     .table thead th, .table tbody td {
                            text-align: left;
                     }
              }
       }
}