@import "src/client_customizations/scss/client_master.scss";

.SelectedCard {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  background: #FFFFFF;
  border: 4px solid #0079C1;
  border-radius: 4px;

  .contact-dealer {
    a {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;


      color: #0079C1;
    }
  }

  .website-dealer {
    margin: auto auto 0;

    a {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;


      color: #505357;
    }
  }
}

.SelectedCard h5 {
  font-size: 1em;
}

.SelectedCard .address-container {
  color: #666666; 
  font-weight: 200;
}

.SelectedCard .oem-image {
  max-width: 150px;
  margin-bottom: 1.5em;
}
