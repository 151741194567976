@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }

    label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        
        color: #2A2A2A;

        margin: 0 0 8px;

    }
}