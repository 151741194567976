@import "src/client_customizations/scss/client_master.scss";

.TravelRouteMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
  border: $ChargingMap-dealer-map-container-border;
  border-radius: $ChargingMap-dealer-map-container-border-radius;
}

.TravelRouteMap .route-distance-legend {
  background: black;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  font-size: 0.75em;
  color: white;
  bottom: 0;
  left: 0;
}

.TravelRouteMap label {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
  color: #666666;
  text-transform: uppercase;
}
.TravelRouteMap {
  padding: 30px;
}
