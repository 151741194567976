@import "src/client_customizations/scss/client_master.scss";

a {
	text-decoration: none !important;
	a:hover {
		text-decoration: none !important;		
	}
}

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
		position: relative;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
.gradient-top-left{
	width: 100%;
	height: 475px;
	position: absolute;

	
	background: linear-gradient(90deg, #000000 16.67%, rgba(196, 196, 196, 0) 100%);
}

.big-promise-text {	
	position: absolute;
	top: 105px;
	margin: 0 calc(50vw - 570px);

	div {
		width: 510px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	h2 {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 48px;
		line-height: 65px;
		text-align: center;
		letter-spacing: 0.15px;
		
		color: #FFFFFF;
	}

	p {
		margin: 16px 0 24px;

		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 18px;

		text-align: center;
		letter-spacing: 0.15px;

		color: #FFFFFF;
	}

	button, .button {
				
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 15px;
		line-height: 24px;

		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.15px;

		padding: 15px 33px;

		color: #FFFFFF;

		background-color: #0079C1;
		border: none;
		border-radius: 2px;
	}

	
	@media (max-width: 1100px) {	
		width: 100%;
		max-width: 100%;

		margin: 0;

		display: flex;
		justify-content: center;
	}
}

#HomepageIncentives {
	padding: 64px 0 96px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 29.69%, rgba(0, 0, 0, 0.512) 100%);
	mix-blend-mode: darken;

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 29.69%, rgba(0, 0, 0, 0.512) 100%);
		mix-blend-mode: darken;		
	}

	.container {
	}

  .textContainer {
    margin: 0 0 48px;

    max-width: 1060px;;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 49px;

      text-align: center;
      letter-spacing: 0.15px;

      color: #FFFFFF;
			z-index: 2;
    }

    p {      
      max-width: 730px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.15px;

      color: #FFFFFF;
			z-index: 2;
    }

		button, .button {
			margin: 0 15px;

			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.15px;

			padding: 15px 33px;

			background: #FFFFFF;
			border: 4px solid #0079C1;
			border-radius: 2px;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 700;
			font-size: 15px;
			line-height: 24px;

			color: #0079C1;
			z-index: 2;
		}

	}
  
}

#HomepageChargingStations {
	padding: 64px 0 96px;
	background-color: #F4F4F4;


  .textContainer {
    margin: 0 0 48px;

    max-width: 1060px;;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 49px;

      text-align: center;
      letter-spacing: 0.15px;

      color: #000000;
			z-index: 2;
    }

    p {      
      max-width: 730px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.15px;

      color: #000000;
			z-index: 2;
    }

		button, .button {
			margin: 0 15px;

			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.15px;

			padding: 15px 33px;

			background: #FFFFFF;
			border: 4px solid #0079C1;
			border-radius: 2px;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 700;
			font-size: 15px;
			line-height: 24px;

			color: #0079C1;
			z-index: 2;
		}

	}

	.ChargingMap {
		max-width: 100%;
		width: 1139px;
		height: 656px;

    padding: 26px;
		
		background: #FFFFFF;
		border: 1px solid #DCDCDC;
		border-radius: 2px;
	}
}
