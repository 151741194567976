@import "src/client_customizations/scss/client_master.scss";

.IncentivePreferences {
	.h5 {
		.badge {
			font-size: 50%;
			vertical-align: middle;
		}
	}
	.tooltip-bubble {
		min-width: 250px;
		max-width: 250px;
	}
	.input-well {
		background-color: $IncentivePreferences-input-well-background-color;
		padding: 0;
		border-radius: 0;
		border: none;
		.btn {
			width: 100%;
		}
	}
	.grid-container {
		display: grid;
		grid-template-columns: 50% 50%;
	}
	.grid-item {
		padding: 20px;
  		text-align: center;
		div {
			div {
				margin-top: 1px !important;
				margin-bottom: 0px !important;
			}
		}
	}
	.pushed-up {
		margin-top: -5px;
	}
	.centered {
		display: flex;
		justify-content: center;
	}
	.personalize-button {
		padding: 20px 40px;
		background-color: #0079C1;
		color: #fff;
		border-radius: 2px;
		font-weight: 700;
		font-size: 15px;
		border: none;
	}
	.label-select {
		margin: 0 0 10px;
    	font-family: 'Open Sans';
    	font-style: normal;
    	font-weight: 400;
    	font-size: 12px;
    	line-height: 16px;
    	display: -ms-flexbox;
    	display: flex;
    	-ms-flex-align: center;
    	align-items: center;
    	text-transform: uppercase;
    	color: #2A2A2A;
	}
}
