@import "../../scss/client_master.scss";

.RateComparison {
  min-height: 500px;

  .RateTotals {
    .title {
      font-size: 12px;
    }

    .lowest {
      color: $green;
    }
  }

  .btn {
    font-weight: bold;
    border-radius: 0;

    &.btn-default {
      color: #57595D;

      &.active {
        background-color: #005F83;
        color: #ffffff;
        border: 1px solid transparent;
      }
    }
  }

  label {
    font-weight: 700;
    text-transform: uppercase;
  }

  .charging-program-link {
    font-size: 11px;
    font-weight: bold;
  }

  .MobileRateTotals {
    background-color: #fff;
    padding: 1em;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);

    .MobileRateTotal {
      text-align: center;
      .total-container {
        background-color: #F6F8FB;
        padding: 1em;

        .lowest {
          color: $green;
        }
      }

      hr {
        padding: 1rem 0;
      }
    }
  }
}

.rate-comparison-title {
  background: #0079C1;
  padding:15px 25px;

  h3 {
    color:white;
    margin:0;
    font-size:20px;
    font-weight: 100;
  }
}

.chart-details-container {
  background-color:#ffffff;
  padding:25px;
  border:1px solid #DBDBDB;
  font-size:14px;
  line-height: 1.5em;

  h2 {
    font-weight: 100;
    margin-top:35px;
    font-size:22px;

    &:first-child {
      margin-top:0;
    }
  }

  p {
    font-size:14px;
    line-height: 1.7em;
    font-weight: 100;

    strong {
      font-weight: bold;
    }
  }

  button.details-button {
    border:1px solid #0079C1;
    color:#0079C1;
    padding:10px 30px;
    background-color:white;
    font-weight: bold;
    font-size:14px;
    margin:15px 0 0;
  }
}
