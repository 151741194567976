@import "src/client_customizations/scss/client_master.scss";

.HomeChargers {
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #333333;
  }

  .subtitle {
    margin: 12px 0 24px;

    max-width: 600px;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    text-align: center;

    color: #333333;
  }

  .btn-main-blue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 0 38px;
    width: 220px;
    padding: 8px 0;
    border: none;
        
    background: #0079C1;
    border-radius: 2px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    /* or 160% */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    
    color: #FFFFFF;
  }

  .amount-text {
    height: 50px;
    margin: 0;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;

    color: #000000;
  }

  .make {
    margin: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #2B2B2B;
  }

  .model {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    
    display: flex;
    align-items: center;
    
    color: #505357;
  }

  .dataRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dataColumn {
    height: 133px;
    padding: 29px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  @media (max-width:768px) {
    .img-charger {    
      width: 65px !important
    }
  }
  .img-charger {    
      width: 80px;
    }

  .charger-row {
    margin: 0 0 9px;

    img {
      width: 28px;
    }

    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      
      display: flex;
      align-items: center;
      
      color: #505357;
      
      opacity: 0.99;
    }
  }

  .charger-price-container{
    margin: 31px 8px 17px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .charger-price {
    margin: 0 8px 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    
    display: flex;
    align-items: center;
    
    color: #333333;    
  }

  .buy-charger {
    width: 100%;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    border: 4px solid #0079C1;
    border-radius: 2px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: #0079C1;    
  }
  
  .ChargerCard {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .ChargerCardTop {
    padding: 0;
  }

  .ChargerCardBottom {
    margin: auto 0 0;
  }

}
